.myversions {
  .card-list {
    .card-item {
      .time-badge {
        .play-icon {
          display: none;
        }

        &:hover,
        &.time-active {
          .play-icon {
            display: block;
          }
        }
      }
      .box {
        top: -15px;
        position: relative;
      }

      &:last-child {
        .v-line {
          display: none;
        }
      }
    }
  }

  .vid-controls {
    gap: 1px;
    background-color: black;
    border: 1px solid black;

    button {
      display: flex;
      align-items: center;
      justify-items: center;
      background-color: #efefef;
      padding-top: 1px;
      padding-bottom: 1px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}
